@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

/* .container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
} */

.container:before {
  content: "";
  position: absolute;
  width: 2000px;
  height: 2000px;
  border-radius: 50%;
  background: #085399;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  z-index: 6;
  transition: 1.8s ease-in-out;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 36%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
  transition: 1s 0.7s ease-in-out;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5rem;
  overflow: hidden;
  /* grid-column: 1 / 2;
  grid-row: 1 / 2; */
  transition: 0.2s 0.7s ease-in-out;
}

form.sign-in-form {
  z-index: 2;
}

form.sign-up-form {
  z-index: 1;
  opacity: 0;
}

.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  height: 55px;
  background-color: #f0f0f0;
  margin: 10px 0;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.btn {
  width: 150px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 49px;
  cursor: pointer;
  background-color: #5995fd;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  transition: 0.5s;
  font-size: 16px;
}

.btn:hover {
  background-color: #4d84e2;
}

.panels-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 7;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: 0.9s 0.6s ease-in-out;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: auto;
  /* height: 41px; */
  font-weight: 600;
  font-size: 18px;
}

.image {
  width: 100%;
  transition: 1.1s 0.4s ease-in-out;
}

.right-panel .content,
.right-panel .image {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .right-panel .content,
.container.sign-up-mode .right-panel .image {
  transform: translateX(0px);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-in-form {
  z-index: 1;
  opacity: 0;
}

.container.sign-up-mode form.sign-up-form {
  z-index: 2;
  opacity: 1;
}
.logo img {
  max-width: 400px;
}
.logo {
  text-align: center;
  margin-bottom: 50px;
}
.logo_img {
  display: none;
}
@media (max-width: 991px) {
  .logo img {
    max-width: 220px;
  }
  .signin-signup {
    top: 50%;
  }
  .btn.transparent {
    font-size: 14px;
    padding: 10px 20px;
  }
  .input-field input {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  /* .container {
    min-height: 800px;
    height: 100vh;
  }

  .container:before {
    width: 1500px;
    height: 1500px;
    left: 30%;
    bottom: 58%;
    transform: translateX(-50%);
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  } */

  /* .signin-signup {
    width: 100%;
    left: 50%;
    top: 105%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  } */

  /* .panels-container {
    z-index: 10;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  } */

  .panel {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
  }

  .panel .content {
    padding-right: 0%;
    transition: 0.9s 0.8s ease-in-out;
  }

  .panel p {
    font-size: 10px;
    padding: 0.5rem 0;
    margin-bottom: 10px;
  }

  .btn.transparent {
    width: auto;
    height: auto;
    font-size: 14px;
  }

  .panel h3 {
    font-size: 18px;
  }
  /* .logo {
    display: none;
  } */
  .image {
    width: 180px;
    transition: 0.9s 0.6s ease-in-out;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .right-panel .content,
  .right-panel .image {
    transform: translateY(300px);
  }

  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
    left: 50%;
  }
  .logo_img {
    display: none;
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .panel .content {
    padding: 0.5rem 1rem;
  }

  .container:before {
    bottom: 72%;
    left: 50%;
  }

  .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}

@media (max-width: 575px) {
  .container:before {
    width: 100%;
    height: 350px;
    left: 0;
  }
  .signin-signup {
    top: 72%;
    left: 0;
    right: 0;
    margin: auto;
    transform: none;
    width: 100%;
    padding-bottom: 20px;
  }
  .logo {
    display: none;
  }
  .panels-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -15%;
    left: 0;
    display: grid;
    grid-template-columns: initial;
  }
  .left-panel {
    grid-row: 1;
    width: 100%;
    justify-content: center;
  }
  .panel h3,
  .panel p {
    display: none;
  }
  .btn.transparent {
    width: 100%;
    height: auto;
    font-size: 11px;
    padding: 10px 20px;
  }
  .image {
    width: 280px;
  }
  .logo_img {
    display: block;
    max-width: 150px;
    background: #fff;
    margin-bottom: 15px;
  }
}
